import { useEffect, useState } from 'react';

// material
import {
  Card,
  Container,
  Typography,
  Grid,
  Divider,
  Button,
  Stack,
  } from '@mui/material';

import { useMatchService } from '../../services/api/match/MatchService';
import { useNavigate,  useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useSettings from '../../hooks/useSettings';
import { CompetitionMatchsOverviewViewModel, MatchOccurenceOverviewViewModel, PlayerViewModel } from '../../apis/data-contracts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MiscellaneousReducerState } from '../../redux/reducers/miscellaneousReducer';
import { Loading } from '../../components/Loading';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_MANAGER } from '../../routes/paths';
import moment from 'moment';
import { MatchApproveForm } from './components/MatchApproveForm';
import MatchSummaryGoalsList from './components/MatchSummaryGoalsList';
import MatchSummaryFaultsList from './components/MatchSummaryFaultsList';
import { Icon } from '@iconify/react';
import { MButtonGreyWithText } from '../../components/@material-extend';


export default function MatchSummary() {
  const { getMatchOverview } = useMatchService();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // const { themeStretch } = useSettings();
  const [matchOverview, setMatchOverview] = useState<CompetitionMatchsOverviewViewModel | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  // const location = useLocation();

  const [allGoals, setAllGoals] = useState<MatchOccurenceOverviewViewModel[]>([]);
  const [allFaults, setAllFaults] = useState<MatchOccurenceOverviewViewModel[]>([]);

  const [idTeamHome, setIdTeamHome] = useState<number>(0);
  const [idTeamAway, setIdTeamAway] = useState<number>(0);
  const [hrGame, setHrGame] = useState<string>('');
  const [dtGame, setDtGame] = useState<string>('');
  const [place, setPlace] = useState<string>('');
  const [status, setStatus] = useState<string>();

  const [hasOccurence, setHasOccurence] = useState<boolean>(false);
  const [competitionType, setCompetitionType] = useState<string>("");
  const [matchId, setMatchId] = useState<number>(0);
  const [mvp, setMvp] = useState<PlayerViewModel | undefined>();

  const misc = useSelector((state: { miscellaneous: MiscellaneousReducerState }) => state?.miscellaneous);

  const getOverview = () => {
    setLoading(true);
    // const locationCast = (location.state as {matchId? : number});

    // if(!locationCast) navigate(PATH_MANAGER.competition.match.list);
    // if(!locationCast.matchId) navigate(PATH_MANAGER.competition.match.list);

    setMatchId(misc?.selectedMatch?.matchId ?? 0);

    getMatchOverview(misc?.selectedMatch?.matchId ?? 0).then((data) => {
      setMatchOverview((data as CompetitionMatchsOverviewViewModel));
      let allGoals = [...data?.goalsHome as MatchOccurenceOverviewViewModel[], ...data?.goalsAway as MatchOccurenceOverviewViewModel[]];
      allGoals = allGoals.sort((a,b)=> new Date(b.dtCreated ?? '').getTime() - new Date(a.dtCreated ?? '').getTime());
      setAllGoals(allGoals);

      let allFaults = [...data?.faultsCardsHome as MatchOccurenceOverviewViewModel[], ...data?.faultsCardsAway as MatchOccurenceOverviewViewModel[]];
      allFaults = allFaults.sort((a,b)=> new Date(b.dtCreated ?? '').getTime() - new Date(a.dtCreated ?? '').getTime());
      setAllFaults(allFaults);

      if(data?.mvp) setMvp(data.mvp);

      setIdTeamHome(data?.teamHomeId ?? 0);
      setIdTeamAway(data?.teamAwayId ?? 0);
      setHrGame(data?.hrGame ?? '');
      setDtGame(data?.dtGame ?? '');
      setPlace(data?.place ?? '');
      setCompetitionType(data?.competitionType ?? '');
      setStatus(data?.status ?? '');

      setHasOccurence(allGoals.length > 0 || allFaults.length > 0 ? true : false);
      enqueueSnackbar(t('competitionManager:summarySucccessfullyLoaded'), { variant: 'success', autoHideDuration: 3000, className: "snackbar"  })
      setLoading(false);
    });
  };

  useEffect(() => {
    getOverview()
  }, []);

  return (
    <Loading loading={loading}>
      <Page title={t('competitionManager:summary')} fullWidth>
            <Stack alignItems={'flex-start'} justifyContent={'space-between'} direction={'row'} mb={2}>
              <MButtonGreyWithText onClick={() => {navigate(PATH_MANAGER.competition.match.list)}} startIcon={(<Icon icon={"system-uicons:backward"} width={25} height={25}/>)}>
                {t('competitionManager:back')}
              </MButtonGreyWithText>
            </Stack>

            <HeaderBreadcrumbs
              heading={t('competitionManager:summary')}
              links={[
                {
                  name:
                    t('competitionManager:competition') +
                    ': ' +
                    misc.selectedCompetitionGroup?.name,
                  href: PATH_MANAGER.competitionGroups.root,
                },
                {
                  name:
                    t('competitionManager:championship') +
                    ': ' +
                    misc.selectedCompetition?.name,
                  href: PATH_MANAGER.competition.list,
                },
                {
                  name: t('competitionManager:matches'),
                  href: PATH_MANAGER.competition.match.list,
                },
                {
                  name: t('competitionManager:summary'),                  
                },
              ]}
            />

              <MatchApproveForm
                competitionType={competitionType}
                hasOccurence={hasOccurence}
                idTeamHome={idTeamHome}
                idTeamAway={idTeamAway}
                matchId={matchId}
                matchOverview={matchOverview}
                status={status}
                setLoading={setLoading}
                handleGetMatchOverview={getOverview}
              />

            {mvp && 
              <Grid style={{marginTop: 100}}>
                <Typography textAlign={"center"} variant="h6">
                  MVP: {mvp?.name} | ({mvp.nickname} - {mvp.playerNumberDefault})
                </Typography>
              </Grid>
            }


            <Grid style={{marginTop: 100}}>
              <Typography textAlign={"center"} variant="h6">
                {place !== '--' ? place : ''} {dtGame !== '--' ? moment(dtGame).format('DD/MM/yyyy') : ''} {hrGame !== '--' ? hrGame : ''}
              </Typography>
            </Grid>
            

            <MatchSummaryGoalsList
              allGoals={allGoals}
              idTeamHome={idTeamHome}
              matchOverview={matchOverview}
              loading={loading}
            />

            <Divider style={{marginTop: 100, marginBottom: 100}}/>

            <MatchSummaryFaultsList
              allFaults={allFaults}
              idTeamHome={idTeamHome}
              matchOverview={matchOverview}
              loading={loading}              
            />

      </Page>
    </Loading>
  );
}
