import React from "react";
import { Grid, Typography, MenuItem } from "@mui/material";
import { MButtonWithText } from "../../../components/@material-extend";
import MTextInput from "../../../components/@material-extend/MTextInput";
import theme from "../../../theme";
import { CompetitionMatchsOverviewViewModel } from "../../../apis/data-contracts";
import { useMatchService } from '../../../services/api/match/MatchService';
import * as Yup from 'yup';
import { MatchStatusEnum } from '../../../apis/data-contracts';
import { FormikProvider, Form, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { alpha, useTheme } from '@mui/material/styles';

export type MatchApproveFormProps = {
  status: string | undefined;
  hasOccurence: boolean;
  competitionType: string;
  matchOverview: CompetitionMatchsOverviewViewModel | undefined;
  matchId: number;
  idTeamHome: number;
  idTeamAway: number;
  setLoading: (value: boolean) => void;
  handleGetMatchOverview: () => void;
};

export type ApproveStatusMatchForm = {
  winnerTeam: string | number | undefined,
  overtime: string | number | undefined,
  classifiedTeam?: string | number | undefined,
  wo?: string | number | undefined
}

export function MatchApproveForm(props: MatchApproveFormProps) {
  const { approveMatch } = useMatchService();
  const { t } = useTranslation();
  const theme = useTheme();

  const ApproveSchema = Yup.object().shape({
    wo: Yup.number().required(t(`competitionManager:fieldRequired`)),
    overtime: Yup.number().required(t(`competitionManager:fieldRequired`)),
    winnerTeam: Yup.number().required(t(`competitionManager:fieldRequired`)),
    classifiedTeam: props.competitionType ===  'Pontos corridos mata-mata' ? Yup.number().required(t(`competitionManager:fieldRequired`)) : Yup.number().nullable()
  });

  const formik = useFormik<ApproveStatusMatchForm>({
    initialValues: {
      classifiedTeam: undefined,
      overtime: -1,
      winnerTeam: undefined,
      wo: -1
    },
    validateOnChange: true,
    validationSchema: ApproveSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      props.setLoading(true);

        const criteria = {
          extraTime: values.overtime === 1 ? true : false,
          winnerId: values.winnerTeam === -1 ?  0 : values.winnerTeam === 1 ? props.idTeamHome : props.idTeamAway,
          wo: values.wo === 1 ? true : false,
          status: MatchStatusEnum.APPROVED,
          id: props.matchId,
          classifiedTeamId: values.classifiedTeam === 1 ? props.idTeamHome : values.classifiedTeam === 2 ? props.idTeamAway : undefined
        };

        await approveMatch(criteria).then(() => {
          props.handleGetMatchOverview()
      });
    }    
  });

  const { errors, touched, handleSubmit, values, setFieldValue, getFieldProps } = formik;

  return (
    <>
      {(props.status === 'Pendente' || props.status === 'Pre-Aprovada') ? (
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container style={{marginTop: 50, background: "#562B7F", color: "#FFF", padding: 20, borderRadius: 10}}>
              <Grid item md={12} p={2}>
                <Typography textAlign={"left"} variant="overline">
                  {t('competitionManager:summaryApprove:title')}
                </Typography>
                <Typography textAlign={"left"} variant="body1">
                  {t('competitionManager:summaryApprove:body1')}
                </Typography>
                <Typography textAlign={"left"} variant="body1">
                  {t('competitionManager:summaryApprove:body2')}
                </Typography>
                <Typography textAlign={"left"} variant="body1">
                  {t('competitionManager:summaryApprove:body3')}
                </Typography>
              </Grid>

              <Grid container style={{background: alpha(theme.palette.background.default, 1), padding: 20, borderRadius: 10}}>
                <Grid item 
                  md={!props.hasOccurence && props.competitionType === 'Pontos corridos mata-mata' ? 2 : !props.hasOccurence && props.competitionType !== 'Pontos corridos mata-mata' ? 3 : 5 }
                  sm={12}
                  m={1}
                >
                    <MTextInput
                      {...getFieldProps('winnerTeam')}
                      value={values.winnerTeam}
                      label={t("competitionManager:summaryApprove:winner")}
                      onChange={(event: any) => setFieldValue('winnerTeam', event.target.value)}
                      error={Boolean(touched.winnerTeam && errors.winnerTeam)}
                      helperText={touched.winnerTeam && errors.winnerTeam}
                      select
                      style={{borderColor: errors.winnerTeam ? "#F00": "#000"}}
                    >
                      <MenuItem value={-1}>{t("competitionManager:summaryApprove:draw")}</MenuItem>
                      <MenuItem value={1}>{props.matchOverview?.teamHomeName}</MenuItem>
                      <MenuItem value={2}>{props.matchOverview?.teamAwayName}</MenuItem>
                    </MTextInput>
                  </Grid>

                  <Grid item 
                    md={!props.hasOccurence && props.competitionType === 'Pontos corridos mata-mata' ? 2 : !props.hasOccurence && props.competitionType !== 'Pontos corridos mata-mata' ? 3 : 5 }
                    sm={12}
                    m={1}
                  >
                    <MTextInput
                      {...getFieldProps('overtime')}
                      value={values.overtime}
                      label={t("competitionManager:summaryApprove:hasOvertime")}
                      onChange={(event: any) => setFieldValue('overtime', event.target.value)}
                      error={Boolean(touched.overtime && errors.overtime)}
                      helperText={touched.overtime && errors.overtime}
                      select
                    >
                      <MenuItem value={-1}>{t("competitionManager:summaryApprove:no")}</MenuItem>
                      <MenuItem value={1}>{t("competitionManager:summaryApprove:yes")}</MenuItem>
                    </MTextInput>
                  </Grid>
                  {
                    !props.hasOccurence && (
                      <Grid item 
                          md={!props.hasOccurence && props.competitionType === 'Pontos corridos mata-mata' ? 2 : !props.hasOccurence && props.competitionType !== 'Pontos corridos mata-mata' ? 3 : 5 }
                          sm={12}
                          m={1}
                      >
                        <MTextInput
                          {...getFieldProps('wo')}
                          value={values.wo}
                          label={t("competitionManager:summaryApprove:hasWo")}
                          onChange={(event: any) => setFieldValue('wo', event.target.value)}
                          error={Boolean(touched.wo && errors.wo)}
                          helperText={touched.wo && errors.wo}
                          select
                        >
                          <MenuItem value={-1}>{t("competitionManager:summaryApprove:no")}</MenuItem>
                          <MenuItem value={1}>{t("competitionManager:summaryApprove:yes")}</MenuItem>
                        </MTextInput>                            
                    </Grid>
                  )}

                {
                  props.competitionType ===  'Pontos corridos mata-mata' && (

                    <Grid item 
                      md={!props.hasOccurence && props.competitionType === 'Pontos corridos mata-mata' ? 2 : !props.hasOccurence && props.competitionType !== 'Pontos corridos mata-mata' ? 3 : 5 }
                      sm={12} 
                      m={1}
                    >
                      <MTextInput
                        value={values.classifiedTeam}
                        label={t("competitionManager:summaryApprove:classifiedTeam")}
                        onChange={(event: any) => setFieldValue('classifiedTeam', event.target.value)}
                        error={Boolean(touched.classifiedTeam && errors.classifiedTeam)}
                        helperText={touched.classifiedTeam && errors.classifiedTeam}
                        select
                      >
                        <MenuItem value={1}>{props.matchOverview?.teamHomeName}</MenuItem>
                        <MenuItem value={2}>{props.matchOverview?.teamAwayName}</MenuItem>
                      </MTextInput>
                  </Grid>              
                )}

              <Grid item 
                  md={!props.hasOccurence && props.competitionType === 'Pontos corridos mata-mata' ? 2 : !props.hasOccurence && props.competitionType !== 'Pontos corridos mata-mata' ? 2 : 1}
                  sm={12} 
                  m={1}
                  style={{display: 'flex', margin: '0px auto'}} 
              >
                <MButtonWithText fullWidth 
                  style={{marginTop: 10}}
                  variant="contained" 
                  size="large"
                  type='submit'
                  onClick={() => {console.log(values)}}
                >
                  {t("competitionManager:summaryApprove:approove")}
                </MButtonWithText>
              </Grid>
            </Grid>
          </Grid>
          </Form>
        </FormikProvider>
      ):
      (
        <Grid container style={{marginTop: 50, background: "#562B7F", color: "#FFF", padding: 20, borderRadius: 10}}>
          <Grid item md={12} p={2}>
            <Typography textAlign={"center"} variant="h5" style={{color: '#FFF'}}>
              {
                props.status === 'Aprovada' ? t("competitionManager:summaryApprove:approoved") :
                props.status === 'Cancelada' ? t("competitionManager:summaryApprove:canceled") :
                t("competitionManager:summaryApprove:preApprooved")
              }
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}
