/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AccessRoleType {
  Unset = "Unset",
  SysAdmin = "SysAdmin",
  BackofficeAdmin = "BackofficeAdmin",
  Financial = "Financial",
  Sponsor = "Sponsor",
  PlayerManager = "PlayerManager",
  EntityManager = "EntityManager",
  CompetitionManager = "CompetitionManager",
  TeamManager = "TeamManager",
}

export enum AddressFamily {
  Unspecified = "Unspecified",
  Unix = "Unix",
  InterNetwork = "InterNetwork",
  ImpLink = "ImpLink",
  Pup = "Pup",
  Chaos = "Chaos",
  NS = "NS",
  Osi = "Osi",
  Ecma = "Ecma",
  DataKit = "DataKit",
  Ccitt = "Ccitt",
  Sna = "Sna",
  DecNet = "DecNet",
  DataLink = "DataLink",
  Lat = "Lat",
  HyperChannel = "HyperChannel",
  AppleTalk = "AppleTalk",
  NetBios = "NetBios",
  VoiceView = "VoiceView",
  FireFox = "FireFox",
  Banyan = "Banyan",
  Atm = "Atm",
  InterNetworkV6 = "InterNetworkV6",
  Cluster = "Cluster",
  Ieee12844 = "Ieee12844",
  Irda = "Irda",
  NetworkDesigners = "NetworkDesigners",
  Max = "Max",
  Packet = "Packet",
  ControllerAreaNetwork = "ControllerAreaNetwork",
  Unknown = "Unknown",
}

export interface ApplyClause {
  transformations?: TransformationNode[] | null;
}

export interface ApplyQueryOption {
  context?: ODataQueryContext;
  resultClrType?: Type;
  applyClause?: ApplyClause;
  rawValue?: string | null;
}

export interface ApproveStatusMatchCriteria {
  wo?: boolean;
  extraTime?: boolean;
  /** @format int64 */
  winnerId?: number;
  /** @format int64 */
  id?: number;
  status?: MatchStatusEnum;
  /** @format int64 */
  classifiedTeamId?: number | null;
}

export interface AsnEncodedData {
  oid?: Oid;
  /** @format byte */
  rawData?: string | null;
}

export interface Assembly {
  definedTypes?: TypeInfo[] | null;
  exportedTypes?: Type[] | null;
  /** @deprecated */
  codeBase?: string | null;
  entryPoint?: MethodInfo;
  fullName?: string | null;
  imageRuntimeVersion?: string | null;
  isDynamic?: boolean;
  location?: string | null;
  reflectionOnly?: boolean;
  isCollectible?: boolean;
  isFullyTrusted?: boolean;
  customAttributes?: CustomAttributeData[] | null;
  /** @deprecated */
  escapedCodeBase?: string | null;
  manifestModule?: Module;
  modules?: Module[] | null;
  /** @deprecated */
  globalAssemblyCache?: boolean;
  /** @format int64 */
  hostContext?: number;
  securityRuleSet?: SecurityRuleSet;
}

export interface AsymmetricAlgorithm {
  /** @format int32 */
  keySize?: number;
  legalKeySizes?: KeySizes[] | null;
  signatureAlgorithm?: string | null;
  keyExchangeAlgorithm?: string | null;
}

export interface ByteReadOnlyMemory {
  /** @format int32 */
  length?: number;
  isEmpty?: boolean;
  span?: ByteReadOnlySpan;
}

export interface ByteReadOnlySpan {
  /** @format int32 */
  length?: number;
  isEmpty?: boolean;
}

export enum CallingConventions {
  Standard = "Standard",
  VarArgs = "VarArgs",
  Any = "Any",
  HasThis = "HasThis",
  ExplicitThis = "ExplicitThis",
}

export interface CancellationToken {
  isCancellationRequested?: boolean;
  canBeCanceled?: boolean;
  waitHandle?: WaitHandle;
}

export interface CityViewModel {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  continentId?: number;
  continentName?: string | null;
  /** @format int64 */
  countryId?: number;
  countryName?: string | null;
  /** @format int64 */
  stateId?: number | null;
  stateName?: string | null;
  stateCode?: string | null;
  code?: string | null;
  name?: string | null;
}

export interface Claim {
  issuer?: string | null;
  originalIssuer?: string | null;
  properties?: Record<string, string>;
  subject?: ClaimsIdentity;
  type?: string | null;
  value?: string | null;
  valueType?: string | null;
}

export interface ClaimsIdentity {
  authenticationType?: string | null;
  isAuthenticated?: boolean;
  actor?: ClaimsIdentity;
  bootstrapContext?: any;
  claims?: Claim[] | null;
  label?: string | null;
  name?: string | null;
  nameClaimType?: string | null;
  roleClaimType?: string | null;
}

export interface ClaimsPrincipal {
  claims?: Claim[] | null;
  identities?: ClaimsIdentity[] | null;
  identity?: IIdentity;
}

export interface CompetitionGroupCriteria {
  /** @format int64 */
  id?: number;
  name?: string | null;
  /** @format int64 */
  idOrganization?: number;
  /** @format int32 */
  modality?: number;
  city?: string | null;
  state?: string | null;
  /** @format int64 */
  countryId?: number;
  image?: FileCriteria;
}

export interface CompetitionGroupViewModel {
  /** @format int64 */
  id?: number;
  name?: string | null;
  /** @format int64 */
  idOrganization?: number;
  modality?: CompetitionModalitiesEnum;
  modalityDisplay?: string | null;
  city?: string | null;
  state?: string | null;
  /** @format int64 */
  countryId?: number;
  logoImageLink?: string | null;
  /** @format int32 */
  championshipsCount?: number;
}

export interface CompetitionGroupViewModelETag {
  isWellFormed?: boolean;
  entityType?: Type;
  isAny?: boolean;
  isIfNoneMatch?: boolean;
}

export interface CompetitionGroupViewModelIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: CompetitionGroupViewModel;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface CompetitionGroupViewModelODataQueryOptions {
  request?: HttpRequest;
  context?: ODataQueryContext;
  rawValues?: ODataRawQueryOptions;
  selectExpand?: SelectExpandQueryOption;
  apply?: ApplyQueryOption;
  compute?: ComputeQueryOption;
  filter?: FilterQueryOption;
  search?: SearchQueryOption;
  orderBy?: OrderByQueryOption;
  skip?: SkipQueryOption;
  skipToken?: SkipTokenQueryOption;
  top?: TopQueryOption;
  count?: CountQueryOption;
  validator?: IODataQueryValidator;
  ifMatch?: CompetitionGroupViewModelETag;
  ifNoneMatch?: CompetitionGroupViewModelETag;
}

export interface CompetitionInviteCriteria {
  /** @format int64 */
  teamId?: number;
  /** @format int64 */
  competitionId?: number;
  playersId?: number[] | null;
  emails?: string[] | null;
  position?: string | null;
  coach?: string | null;
}

export interface CompetitionMatchsOverviewViewModel {
  statusSumula?: string | null;
  groupName?: string | null;
  teamHomeName?: string | null;
  teamHomeNickName?: string | null;
  /** @format int32 */
  goalsAway?: number;
  /** @format int32 */
  goalsHome?: number;
  /** @format int32 */
  goalsPrAway?: number | null;
  /** @format int32 */
  goalsPrHome?: number | null;
  /** @format int32 */
  goalsPeAway?: number | null;
  /** @format int32 */
  goalsPeHome?: number | null;
  teamAwayName?: string | null;
  teamAwayNickName?: string | null;
  dtGame?: string | null;
  hrGame?: string | null;
  place?: string | null;
  /** @format int32 */
  game?: number;
  round?: string | null;
  gameOrder?: string | null;
  phase?: string | null;
  /** @format int64 */
  idTeamHome?: number;
  /** @format int64 */
  matchId?: number;
  token?: string | null;
  /** @format int64 */
  idTeamAway?: number;
  hasWo?: boolean;
  /** @format int64 */
  idTeamWinnerMatch?: number | null;
  /** @format int64 */
  idTeamWinner?: number;
  undefinedTeams?: boolean;
}

export interface CompetitionMatchsOverviewViewModelETag {
  isWellFormed?: boolean;
  entityType?: Type;
  isAny?: boolean;
  isIfNoneMatch?: boolean;
}

export interface CompetitionMatchsOverviewViewModelIEnumerableIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: CompetitionMatchsOverviewViewModel[] | null;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface CompetitionMatchsOverviewViewModelODataQueryOptions {
  request?: HttpRequest;
  context?: ODataQueryContext;
  rawValues?: ODataRawQueryOptions;
  selectExpand?: SelectExpandQueryOption;
  apply?: ApplyQueryOption;
  compute?: ComputeQueryOption;
  filter?: FilterQueryOption;
  search?: SearchQueryOption;
  orderBy?: OrderByQueryOption;
  skip?: SkipQueryOption;
  skipToken?: SkipTokenQueryOption;
  top?: TopQueryOption;
  count?: CountQueryOption;
  validator?: IODataQueryValidator;
  ifMatch?: CompetitionMatchsOverviewViewModelETag;
  ifNoneMatch?: CompetitionMatchsOverviewViewModelETag;
}

export enum CompetitionModalitiesEnum {
  FUTSAL = "FUTSAL",
  CAMPO = "CAMPO",
  FUTEBOL7 = "FUTEBOL7",
  SOCIETY = "SOCIETY",
  AREIA = "AREIA",
}

export interface CompetitionOptionsViewModel {
  modalities?: string[] | null;
  divisions?: string[] | null;
  categories?: string[] | null;
  contexts?: string[] | null;
  classificationTypes?: string[] | null;
}

export interface CompetitionOptionsViewModelIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: CompetitionOptionsViewModel;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface CompetitionTeamPlayerCriteria {
  /** @format int64 */
  competitionId?: number;
  /** @format int64 */
  teamId?: number;
  /** @format int64 */
  playerId?: number;
  position?: string | null;
  coach?: string | null;
  playerNumberDefault?: string | null;
  doc?: string | null;
  birthday?: string | null;
  docFederacao?: string | null;
  docCbf?: string | null;
  docCbfs?: string | null;
  docOther?: string | null;
  phone?: string | null;
  docCpf?: string | null;
}

export interface CompetitionTeamPlayerSubscribeViewModel {
  nickname?: string | null;
  name?: string | null;
  position?: string | null;
  playerNumberDefault?: string | null;
  coach?: string | null;
  email?: string | null;
  doc?: string | null;
  birthday?: string | null;
  docFederacao?: string | null;
  docCbf?: string | null;
  docCbfs?: string | null;
  /** @format int64 */
  loginId?: number;
  /** @format int64 */
  playerId?: number;
  /** @format int64 */
  teamId?: number;
  hasMatchOccurrences?: boolean;
  hasAlreadyPlayedOnCompetition?: boolean;
  /** @format date-time */
  dtCreated?: string;
}

export interface CompetitionTeamPlayerSubscribeViewModelETag {
  isWellFormed?: boolean;
  entityType?: Type;
  isAny?: boolean;
  isIfNoneMatch?: boolean;
}

export interface CompetitionTeamPlayerSubscribeViewModelIQueryableIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: CompetitionTeamPlayerSubscribeViewModel[] | null;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface CompetitionTeamPlayerSubscribeViewModelIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: CompetitionTeamPlayerSubscribeViewModel;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface CompetitionTeamPlayerSubscribeViewModelODataQueryOptions {
  request?: HttpRequest;
  context?: ODataQueryContext;
  rawValues?: ODataRawQueryOptions;
  selectExpand?: SelectExpandQueryOption;
  apply?: ApplyQueryOption;
  compute?: ComputeQueryOption;
  filter?: FilterQueryOption;
  search?: SearchQueryOption;
  orderBy?: OrderByQueryOption;
  skip?: SkipQueryOption;
  skipToken?: SkipTokenQueryOption;
  top?: TopQueryOption;
  count?: CountQueryOption;
  validator?: IODataQueryValidator;
  ifMatch?: CompetitionTeamPlayerSubscribeViewModelETag;
  ifNoneMatch?: CompetitionTeamPlayerSubscribeViewModelETag;
}

export interface CompetitionViewModel {
  /** @format int64 */
  id?: number;
  name?: string | null;
  nameResp?: string | null;
  emailResp?: string | null;
  category?: string | null;
  division?: string | null;
  modality?: string | null;
  season?: string | null;
  uf?: string | null;
  cityName?: string | null;
  type1?: string | null;
  type2?: string | null;
  type3?: string | null;
  type4?: string | null;
  oficial?: string | null;
  /** @format int32 */
  nTeams?: number;
  /** @format int32 */
  nTeamsNext?: number;
  /** @format int32 */
  nGroups?: number;
  /** @format int32 */
  count?: number;
  matchsTable?: string | null;
  status?: string | null;
  /** @format int64 */
  createdByLogin?: number;
  /** @format date-time */
  dtCreated?: string;
  dtCanceled?: string | null;
  dtStarted?: string | null;
  dtEnded?: string | null;
  dtUpdated?: string | null;
  enrolPeriod?: string | null;
  orgSource?: string | null;
  showInApp?: string | null;
  /** @format int64 */
  cityId?: number | null;
  bulletinFooterUrl?: string | null;
  presidentName?: string | null;
  presidentContact?: string | null;
  logoUrl?: string | null;
  generateBulletin?: boolean;
  /** @format int64 */
  competitionGroupId?: number;
  nameResposible?: string | null;
  /** @format int32 */
  countPlayers?: number | null;
  /** @format int64 */
  stateId?: number | null;
  /** @format int64 */
  countryId?: number | null;
  fixedPlace?: string | null;
  logo?: string | null;
}

export interface CompetitionViewModelETag {
  isWellFormed?: boolean;
  entityType?: Type;
  isAny?: boolean;
  isIfNoneMatch?: boolean;
}

export interface CompetitionViewModelIEnumerableIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: CompetitionViewModel[] | null;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface CompetitionViewModelIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: CompetitionViewModel;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface CompetitionViewModelODataQueryOptions {
  request?: HttpRequest;
  context?: ODataQueryContext;
  rawValues?: ODataRawQueryOptions;
  selectExpand?: SelectExpandQueryOption;
  apply?: ApplyQueryOption;
  compute?: ComputeQueryOption;
  filter?: FilterQueryOption;
  search?: SearchQueryOption;
  orderBy?: OrderByQueryOption;
  skip?: SkipQueryOption;
  skipToken?: SkipTokenQueryOption;
  top?: TopQueryOption;
  count?: CountQueryOption;
  validator?: IODataQueryValidator;
  ifMatch?: CompetitionViewModelETag;
  ifNoneMatch?: CompetitionViewModelETag;
}

export interface ComputeClause {
  computedItems?: ComputeExpression[] | null;
}

export interface ComputeExpression {
  expression?: SingleValueNode;
  alias?: string | null;
  typeReference?: IEdmTypeReference;
}

export interface ComputeQueryOption {
  context?: ODataQueryContext;
  resultClrType?: Type;
  computeClause?: ComputeClause;
  rawValue?: string | null;
  validator?: IComputeQueryValidator;
}

export interface ConnectionInfo {
  id?: string | null;
  remoteIpAddress?: IPAddress;
  /** @format int32 */
  remotePort?: number;
  localIpAddress?: IPAddress;
  /** @format int32 */
  localPort?: number;
  clientCertificate?: X509Certificate2;
}

export interface ConstructorInfo {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  /** @format int32 */
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  memberType?: MemberTypes;
}

export interface CountQueryOption {
  context?: ODataQueryContext;
  rawValue?: string | null;
  value?: boolean;
  validator?: ICountQueryValidator;
}

export interface CountryViewModel {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  continentId?: number;
  continentName?: string | null;
  code?: string | null;
  name?: string | null;
}

export interface CreateCompetitionCriteria {
  /** @format int64 */
  id?: number;
  name?: string | null;
  nameResp?: string | null;
  emailResp?: string | null;
  category?: string | null;
  division?: string | null;
  modality?: string | null;
  season?: string | null;
  uf?: string | null;
  cityName?: string | null;
  /** @format int64 */
  cityId?: number | null;
  type1?: string | null;
  type2?: string | null;
  type3?: string | null;
  type4?: string | null;
  oficial?: string | null;
  /** @format int32 */
  nTeams?: number;
  /** @format int32 */
  nGroups?: number;
  orgSource?: string | null;
  showInApp?: string | null;
  enrolPeriod?: string | null;
  /** @format int32 */
  nTeamsNext?: number;
  fixedPlace?: string | null;
  /** @format int64 */
  competitionGroupId?: number;
  image?: FileCriteria;
}

export interface CreateMatchCriteria {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  game?: number;
  phase?: string | null;
  cGroup?: string | null;
  round?: string | null;
  /** @format int64 */
  teamHomeId?: number | null;
  /** @format int64 */
  teamAwayId?: number | null;
  iniLabelTeamHome?: string | null;
  iniLabelTeamAway?: string | null;
  /** @format int64 */
  competitionId?: number;
  place?: string | null;
  /** @format date-time */
  dtGame?: string | null;
  hrGame?: TimeSpan;
  /** @format int32 */
  count?: number;
  gameOrder?: string | null;
  /** @format int32 */
  daysMatchRestriction?: number;
  undefinedTeams?: boolean;
}

export interface CreateTeamCriteria {
  /** @format int64 */
  id?: number | null;
  name?: string | null;
  nameResp?: string | null;
  modality?: CompetitionModalitiesEnum;
  uf?: string | null;
  nickname?: string | null;
  emailResp?: string | null;
  colors?: string | null;
  cityName?: string | null;
  /** @format int64 */
  createdByLogin?: number | null;
  image?: FileCriteria;
}

export interface CustomAttributeData {
  attributeType?: Type;
  constructor?: ConstructorInfo;
  constructorArguments?: CustomAttributeTypedArgument[] | null;
  namedArguments?: CustomAttributeNamedArgument[] | null;
}

export interface CustomAttributeNamedArgument {
  memberInfo?: MemberInfo;
  typedValue?: CustomAttributeTypedArgument;
  memberName?: string | null;
  isField?: boolean;
}

export interface CustomAttributeTypedArgument {
  argumentType?: Type;
  value?: any;
}

export enum DefaultLanguageEnum {
  PTBR = "PTBR",
  ESES = "ESES",
  ENUS = "ENUS",
}

export interface DefaultQueryConfigurations {
  enableExpand?: boolean;
  enableSelect?: boolean;
  enableCount?: boolean;
  enableOrderBy?: boolean;
  enableFilter?: boolean;
  /** @format int32 */
  maxTop?: number | null;
  enableSkipToken?: boolean;
}

export interface ETag {
  isWellFormed?: boolean;
  entityType?: Type;
  isAny?: boolean;
  isIfNoneMatch?: boolean;
}

export enum EdmContainerElementKind {
  None = "None",
  EntitySet = "EntitySet",
  ActionImport = "ActionImport",
  FunctionImport = "FunctionImport",
  Singleton = "Singleton",
}

export enum EdmExpressionKind {
  None = "None",
  BinaryConstant = "BinaryConstant",
  BooleanConstant = "BooleanConstant",
  DateTimeOffsetConstant = "DateTimeOffsetConstant",
  DecimalConstant = "DecimalConstant",
  FloatingConstant = "FloatingConstant",
  GuidConstant = "GuidConstant",
  IntegerConstant = "IntegerConstant",
  StringConstant = "StringConstant",
  DurationConstant = "DurationConstant",
  Null = "Null",
  Record = "Record",
  Collection = "Collection",
  Path = "Path",
  If = "If",
  Cast = "Cast",
  IsType = "IsType",
  FunctionApplication = "FunctionApplication",
  LabeledExpressionReference = "LabeledExpressionReference",
  Labeled = "Labeled",
  PropertyPath = "PropertyPath",
  NavigationPropertyPath = "NavigationPropertyPath",
  DateConstant = "DateConstant",
  TimeOfDayConstant = "TimeOfDayConstant",
  EnumMember = "EnumMember",
  AnnotationPath = "AnnotationPath",
}

export enum EdmOnDeleteAction {
  None = "None",
  Cascade = "Cascade",
}

export enum EdmPropertyKind {
  None = "None",
  Structural = "Structural",
  Navigation = "Navigation",
}

export interface EdmReferentialConstraintPropertyPair {
  dependentProperty?: IEdmStructuralProperty;
  principalProperty?: IEdmStructuralProperty;
}

export enum EdmSchemaElementKind {
  None = "None",
  TypeDefinition = "TypeDefinition",
  Term = "Term",
  Action = "Action",
  EntityContainer = "EntityContainer",
  Function = "Function",
}

export enum EdmTypeKind {
  None = "None",
  Primitive = "Primitive",
  Entity = "Entity",
  Complex = "Complex",
  Collection = "Collection",
  EntityReference = "EntityReference",
  Enum = "Enum",
  TypeDefinition = "TypeDefinition",
  Untyped = "Untyped",
  Path = "Path",
}

export enum EventAttributes {
  None = "None",
  SpecialName = "SpecialName",
  RTSpecialName = "RTSpecialName",
}

export interface EventInfo {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  /** @format int32 */
  metadataToken?: number;
  memberType?: MemberTypes;
  attributes?: EventAttributes;
  isSpecialName?: boolean;
  addMethod?: MethodInfo;
  removeMethod?: MethodInfo;
  raiseMethod?: MethodInfo;
  isMulticast?: boolean;
  eventHandlerType?: Type;
}

export enum FieldAttributes {
  PrivateScope = "PrivateScope",
  Private = "Private",
  FamANDAssem = "FamANDAssem",
  Assembly = "Assembly",
  Family = "Family",
  FamORAssem = "FamORAssem",
  Public = "Public",
  FieldAccessMask = "FieldAccessMask",
  Static = "Static",
  InitOnly = "InitOnly",
  Literal = "Literal",
  NotSerialized = "NotSerialized",
  HasFieldRVA = "HasFieldRVA",
  SpecialName = "SpecialName",
  RTSpecialName = "RTSpecialName",
  HasFieldMarshal = "HasFieldMarshal",
  PinvokeImpl = "PinvokeImpl",
  HasDefault = "HasDefault",
  ReservedMask = "ReservedMask",
}

export interface FieldInfo {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  /** @format int32 */
  metadataToken?: number;
  memberType?: MemberTypes;
  attributes?: FieldAttributes;
  fieldType?: Type;
  isInitOnly?: boolean;
  isLiteral?: boolean;
  isNotSerialized?: boolean;
  isPinvokeImpl?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  fieldHandle?: RuntimeFieldHandle;
}

export interface FileCriteria {
  fileName?: string | null;
  extension?: string | null;
  encodedBase64?: string | null;
}

export interface FilterClause {
  expression?: SingleValueNode;
  rangeVariable?: RangeVariable;
  itemType?: IEdmTypeReference;
}

export interface FilterQueryOption {
  context?: ODataQueryContext;
  validator?: IFilterQueryValidator;
  compute?: ComputeQueryOption;
  filterClause?: FilterClause;
  rawValue?: string | null;
}

export interface ForgotPasswordCriteria {
  login?: string | null;
}

export enum GenericParameterAttributes {
  None = "None",
  Covariant = "Covariant",
  Contravariant = "Contravariant",
  VarianceMask = "VarianceMask",
  ReferenceTypeConstraint = "ReferenceTypeConstraint",
  NotNullableValueTypeConstraint = "NotNullableValueTypeConstraint",
  DefaultConstructorConstraint = "DefaultConstructorConstraint",
  SpecialConstraintMask = "SpecialConstraintMask",
}

export interface HostString {
  value?: string | null;
  hasValue?: boolean;
  host?: string | null;
  /** @format int32 */
  port?: number | null;
}

export interface HttpContext {
  features?: TypeObjectKeyValuePair[] | null;
  request?: HttpRequest;
  response?: HttpResponse;
  connection?: ConnectionInfo;
  webSockets?: WebSocketManager;
  user?: ClaimsPrincipal;
  items?: Record<string, any>;
  requestServices?: IServiceProvider;
  requestAborted?: CancellationToken;
  traceIdentifier?: string | null;
  session?: ISession;
}

export interface HttpRequest {
  httpContext?: HttpContext;
  method?: string | null;
  scheme?: string | null;
  isHttps?: boolean;
  host?: HostString;
  pathBase?: PathString;
  path?: PathString;
  queryString?: QueryString;
  query?: StringStringValuesKeyValuePair[] | null;
  protocol?: string | null;
  headers?: Record<string, string[]>;
  cookies?: StringStringKeyValuePair[] | null;
  /** @format int64 */
  contentLength?: number | null;
  contentType?: string | null;
  body?: Stream;
  bodyReader?: PipeReader;
  hasFormContentType?: boolean;
  form?: StringStringValuesKeyValuePair[] | null;
  routeValues?: Record<string, any>;
}

export interface HttpResponse {
  httpContext?: HttpContext;
  /** @format int32 */
  statusCode?: number;
  headers?: Record<string, string[]>;
  body?: Stream;
  bodyWriter?: PipeWriter;
  /** @format int64 */
  contentLength?: number | null;
  contentType?: string | null;
  cookies?: IResponseCookies;
  hasStarted?: boolean;
}

export type IActionResult = object;

export type IComputeQueryValidator = object;

export type ICountQueryValidator = object;

export type ICustomAttributeProvider = object;

export type IEdmDirectValueAnnotationsManager = object;

export interface IEdmEntityContainer {
  elements?: IEdmEntityContainerElement[] | null;
  schemaElementKind?: EdmSchemaElementKind;
  namespace?: string | null;
  name?: string | null;
}

export interface IEdmEntityContainerElement {
  containerElementKind?: EdmContainerElementKind;
  container?: IEdmEntityContainer;
  name?: string | null;
}

export interface IEdmExpression {
  expressionKind?: EdmExpressionKind;
}

export interface IEdmModel {
  schemaElements?: IEdmSchemaElement[] | null;
  vocabularyAnnotations?: IEdmVocabularyAnnotation[] | null;
  referencedModels?: IEdmModel[] | null;
  declaredNamespaces?: string[] | null;
  directValueAnnotationsManager?: IEdmDirectValueAnnotationsManager;
  entityContainer?: IEdmEntityContainer;
}

export interface IEdmNavigationProperty {
  partner?: IEdmNavigationProperty;
  onDelete?: EdmOnDeleteAction;
  containsTarget?: boolean;
  referentialConstraint?: IEdmReferentialConstraint;
  propertyKind?: EdmPropertyKind;
  type?: IEdmTypeReference;
  declaringType?: IEdmStructuredType;
  name?: string | null;
}

export interface IEdmNavigationPropertyBinding {
  navigationProperty?: IEdmNavigationProperty;
  target?: IEdmNavigationSource;
  path?: IEdmPathExpression;
}

export interface IEdmNavigationSource {
  navigationPropertyBindings?: IEdmNavigationPropertyBinding[] | null;
  path?: IEdmPathExpression;
  type?: IEdmType;
  name?: string | null;
}

export interface IEdmPathExpression {
  pathSegments?: string[] | null;
  path?: string | null;
  expressionKind?: EdmExpressionKind;
}

export interface IEdmProperty {
  propertyKind?: EdmPropertyKind;
  type?: IEdmTypeReference;
  declaringType?: IEdmStructuredType;
  name?: string | null;
}

export interface IEdmReferentialConstraint {
  propertyPairs?: EdmReferentialConstraintPropertyPair[] | null;
}

export interface IEdmSchemaElement {
  schemaElementKind?: EdmSchemaElementKind;
  namespace?: string | null;
  name?: string | null;
}

export interface IEdmStructuralProperty {
  defaultValueString?: string | null;
  propertyKind?: EdmPropertyKind;
  type?: IEdmTypeReference;
  declaringType?: IEdmStructuredType;
  name?: string | null;
}

export interface IEdmStructuredType {
  isAbstract?: boolean;
  isOpen?: boolean;
  baseType?: IEdmStructuredType;
  declaredProperties?: IEdmProperty[] | null;
  typeKind?: EdmTypeKind;
}

export interface IEdmTerm {
  type?: IEdmTypeReference;
  appliesTo?: string | null;
  defaultValue?: string | null;
  schemaElementKind?: EdmSchemaElementKind;
  namespace?: string | null;
  name?: string | null;
}

export interface IEdmType {
  typeKind?: EdmTypeKind;
}

export interface IEdmTypeReference {
  isNullable?: boolean;
  definition?: IEdmType;
}

export type IEdmVocabularyAnnotatable = object;

export interface IEdmVocabularyAnnotation {
  qualifier?: string | null;
  term?: IEdmTerm;
  target?: IEdmVocabularyAnnotatable;
  value?: IEdmExpression;
}

export type IFilterQueryValidator = object;

export interface IIdentity {
  name?: string | null;
  authenticationType?: string | null;
  isAuthenticated?: boolean;
}

export type IODataQueryValidator = object;

export type IOrderByQueryValidator = object;

export interface IPAddress {
  addressFamily?: AddressFamily;
  /** @format int64 */
  scopeId?: number;
  isIPv6Multicast?: boolean;
  isIPv6LinkLocal?: boolean;
  isIPv6SiteLocal?: boolean;
  isIPv6Teredo?: boolean;
  isIPv6UniqueLocal?: boolean;
  isIPv4MappedToIPv6?: boolean;
  /**
   * @deprecated
   * @format int64
   */
  address?: number;
}

export type IResponseCookies = object;

export type ISelectExpandQueryValidator = object;

export type IServiceProvider = object;

export interface ISession {
  isAvailable?: boolean;
  id?: string | null;
  keys?: string[] | null;
}

export type ISkipQueryValidator = object;

export type ISkipTokenQueryValidator = object;

export type ITopQueryValidator = object;

export interface Int32StringKeyValuePair {
  /** @format int32 */
  key?: number;
  value?: string | null;
}

export interface Int32StringKeyValuePairListResultBase {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: Int32StringKeyValuePair[] | null;
  isSuccessStatusCode?: boolean;
  /** @format int32 */
  totalCount?: number | null;
  validationErros?: string[] | null;
}

export type IntPtr = object;

export interface KeySizes {
  /** @format int32 */
  minSize?: number;
  /** @format int32 */
  maxSize?: number;
  /** @format int32 */
  skipSize?: number;
}

export enum LayoutKind {
  Sequential = "Sequential",
  Explicit = "Explicit",
  Auto = "Auto",
}

export interface LoginCriteria {
  login?: string | null;
  password?: string | null;
}

export interface MatchCriteria {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  game?: number;
  phase?: string | null;
  cGroup?: string | null;
  round?: string | null;
  /** @format int64 */
  teamHomeId?: number | null;
  /** @format int64 */
  teamAwayId?: number | null;
  iniLabelTeamHome?: string | null;
  iniLabelTeamAway?: string | null;
  /** @format int64 */
  competitionId?: number;
  place?: string | null;
  /** @format date-time */
  dtGame?: string | null;
  hrGame?: TimeSpan;
  /** @format int32 */
  count?: number;
  gameOrder?: string | null;
  /** @format int32 */
  daysMatchRestriction?: number;
}

export interface MatchOccurenceOverview {
  /** @format int64 */
  teamId?: number;
  teamName?: string | null;
  teamNickName?: string | null;
  /** @format int64 */
  matchId?: number;
  /** @format int64 */
  playerId?: number;
  playerNickName?: string | null;
  playerPosition?: string | null;
  playerNumber?: string | null;
  type?: string | null;
  phase?: string | null;
  gameTime?: string | null;
  occurrenceTime?: string | null;
  dtCreated?: string | null;
}

export interface MatchOccurenceOverviewViewModel {
  /** @format int64 */
  teamId?: number;
  teamName?: string | null;
  teamNickName?: string | null;
  /** @format int64 */
  matchId?: number;
  /** @format int64 */
  playerId?: number;
  playerNickName?: string | null;
  playerPosition?: string | null;
  playerNumber?: string | null;
  type?: string | null;
  phase?: string | null;
  gameTime?: string | null;
  occurrenceTime?: string | null;
  dtCreated?: string | null;
}

export interface MatchOverviewViewModel {
  /** @format int64 */
  teamHomeId?: number;
  teamHomeName?: string | null;
  teamHomeNickName?: string | null;
  teamAwayName?: string | null;
  teamAwayNickName?: string | null;
  competitionType?: string | null;
  /** @format int64 */
  teamAwayId?: number;
  /** @format int32 */
  countGoalsAway?: number;
  /** @format int32 */
  countGoalsHome?: number;
  status?: string | null;
  place?: string | null;
  dtGame?: string | null;
  hrGame?: string | null;
  goalsAway?: MatchOccurenceOverviewViewModel[] | null;
  goalsHome?: MatchOccurenceOverviewViewModel[] | null;
  faultsCardsAway?: MatchOccurenceOverview[] | null;
  faultsCardsHome?: MatchOccurenceOverview[] | null;
  mvp?: PlayerViewModel;
}

export interface MatchOverviewViewModelIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: MatchOverviewViewModel;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export enum MatchStatusEnum {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  CANCELED = "CANCELED",
  PRE_APPROVED = "PRE_APPROVED",
}

export interface MatchViewModel {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  game?: number;
  phase?: string | null;
  cGroup?: string | null;
  round?: string | null;
  /** @format int64 */
  teamHomeId?: number | null;
  /** @format int64 */
  teamAwayId?: number | null;
  /** @format int64 */
  competitionId?: number;
  place?: string | null;
  dtGame?: string | null;
  hrGame?: string | null;
  /** @format int32 */
  count?: number;
  /** @format int64 */
  createdByLogin?: number;
  /** @format int64 */
  updatedByLogin?: number | null;
  dtCreated?: string | null;
  dtUpdated?: string | null;
  statusSumula?: string | null;
  dtApproved?: string | null;
  /** @format int64 */
  teamWinnerId?: number | null;
  extraTime?: string | null;
  wo?: string | null;
  /** @format int64 */
  teamClassifiedId?: number | null;
  iniLabelTeamHome?: string | null;
  iniLabelTeamAway?: string | null;
  gameOrder?: string | null;
  token?: string | null;
  /** @format int64 */
  loginRefereeId?: number;
  /** @format int32 */
  daysMatchRestriction?: number;
}

export interface MatchViewModelIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: MatchViewModel;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface MatchViewModelListIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: MatchViewModel[] | null;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface MatchViewModelListListIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: MatchViewModel[][] | null;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface MemberInfo {
  memberType?: MemberTypes;
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  /** @format int32 */
  metadataToken?: number;
}

export enum MemberTypes {
  Constructor = "Constructor",
  Event = "Event",
  Field = "Field",
  Method = "Method",
  Property = "Property",
  TypeInfo = "TypeInfo",
  Custom = "Custom",
  NestedType = "NestedType",
  All = "All",
}

export enum MethodAttributes {
  ReuseSlot = "ReuseSlot",
  Private = "Private",
  FamANDAssem = "FamANDAssem",
  Assembly = "Assembly",
  Family = "Family",
  FamORAssem = "FamORAssem",
  Public = "Public",
  MemberAccessMask = "MemberAccessMask",
  UnmanagedExport = "UnmanagedExport",
  Static = "Static",
  Final = "Final",
  Virtual = "Virtual",
  HideBySig = "HideBySig",
  NewSlot = "NewSlot",
  CheckAccessOnOverride = "CheckAccessOnOverride",
  Abstract = "Abstract",
  SpecialName = "SpecialName",
  RTSpecialName = "RTSpecialName",
  PinvokeImpl = "PinvokeImpl",
  HasSecurity = "HasSecurity",
  RequireSecObject = "RequireSecObject",
  ReservedMask = "ReservedMask",
}

export interface MethodBase {
  memberType?: MemberTypes;
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  /** @format int32 */
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
}

export enum MethodImplAttributes {
  IL = "IL",
  Native = "Native",
  OPTIL = "OPTIL",
  Runtime = "Runtime",
  Unmanaged = "Unmanaged",
  NoInlining = "NoInlining",
  ForwardRef = "ForwardRef",
  Synchronized = "Synchronized",
  NoOptimization = "NoOptimization",
  PreserveSig = "PreserveSig",
  AggressiveInlining = "AggressiveInlining",
  AggressiveOptimization = "AggressiveOptimization",
  InternalCall = "InternalCall",
  MaxMethodImplVal = "MaxMethodImplVal",
}

export interface MethodInfo {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  /** @format int32 */
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  memberType?: MemberTypes;
  returnParameter?: ParameterInfo;
  returnType?: Type;
  returnTypeCustomAttributes?: ICustomAttributeProvider;
}

export interface Module {
  assembly?: Assembly;
  fullyQualifiedName?: string | null;
  name?: string | null;
  /** @format int32 */
  mdStreamVersion?: number;
  /** @format uuid */
  moduleVersionId?: string;
  scopeName?: string | null;
  moduleHandle?: ModuleHandle;
  customAttributes?: CustomAttributeData[] | null;
  /** @format int32 */
  metadataToken?: number;
}

export interface ModuleHandle {
  /** @format int32 */
  mdStreamVersion?: number;
}

export interface ODataPathSegment {
  edmType?: IEdmType;
  identifier?: string | null;
}

export interface ODataQueryContext {
  defaultQueryConfigurations?: DefaultQueryConfigurations;
  model?: IEdmModel;
  elementType?: IEdmType;
  navigationSource?: IEdmNavigationSource;
  elementClrType?: Type;
  path?: ODataPathSegment[] | null;
  requestContainer?: IServiceProvider;
}

export interface ODataRawQueryOptions {
  filter?: string | null;
  apply?: string | null;
  compute?: string | null;
  search?: string | null;
  orderBy?: string | null;
  top?: string | null;
  skip?: string | null;
  select?: string | null;
  expand?: string | null;
  count?: string | null;
  format?: string | null;
  skipToken?: string | null;
  deltaToken?: string | null;
}

export interface Oid {
  value?: string | null;
  friendlyName?: string | null;
}

export interface OrderByClause {
  thenBy?: OrderByClause;
  expression?: SingleValueNode;
  direction?: OrderByDirection;
  rangeVariable?: RangeVariable;
  itemType?: IEdmTypeReference;
}

export enum OrderByDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export interface OrderByNode {
  direction?: OrderByDirection;
}

export interface OrderByQueryOption {
  context?: ODataQueryContext;
  orderByNodes?: OrderByNode[] | null;
  rawValue?: string | null;
  validator?: IOrderByQueryValidator;
  compute?: ComputeQueryOption;
  orderByClause?: OrderByClause;
}

export interface OrganizationViewModel {
  /** @format date-time */
  creationDate?: string | null;
  /** @format date-time */
  changeDate?: string | null;
  /** @format int64 */
  id?: number;
  name?: string | null;
  document?: string | null;
  /** @format int32 */
  contractType?: number;
  /** @format int32 */
  contractStatus?: number;
  zipCode?: string | null;
  district?: string | null;
  address?: string | null;
  houseNumber?: string | null;
  state?: string | null;
  city?: string | null;
  countryId?: string | null;
  addressDetails?: string | null;
  /** @format int64 */
  idLoginResponsible?: number;
}

export enum ParameterAttributes {
  None = "None",
  In = "In",
  Out = "Out",
  Lcid = "Lcid",
  Retval = "Retval",
  Optional = "Optional",
  HasDefault = "HasDefault",
  HasFieldMarshal = "HasFieldMarshal",
  Reserved3 = "Reserved3",
  Reserved4 = "Reserved4",
  ReservedMask = "ReservedMask",
}

export interface ParameterInfo {
  attributes?: ParameterAttributes;
  member?: MemberInfo;
  name?: string | null;
  parameterType?: Type;
  /** @format int32 */
  position?: number;
  isIn?: boolean;
  isLcid?: boolean;
  isOptional?: boolean;
  isOut?: boolean;
  isRetval?: boolean;
  defaultValue?: any;
  rawDefaultValue?: any;
  hasDefaultValue?: boolean;
  customAttributes?: CustomAttributeData[] | null;
  /** @format int32 */
  metadataToken?: number;
}

export interface PathString {
  value?: string | null;
  hasValue?: boolean;
}

export type PipeReader = object;

export interface PipeWriter {
  canGetUnflushedBytes?: boolean;
  /** @format int64 */
  unflushedBytes?: number;
}

export interface PlayerViewModel {
  /** @format int64 */
  id?: number;
  name?: string | null;
  nickname?: string | null;
  email?: string | null;
  doc?: string | null;
  birthday?: string | null;
  /** @format int64 */
  createdByLogin?: number;
  dtCreated?: string | null;
  dtUpdated?: string | null;
  docFederacao?: string | null;
  docCbf?: string | null;
  docCbfs?: string | null;
  playerNumberDefault?: string | null;
  phone?: string | null;
  docOther?: string | null;
  docCpf?: string | null;
  idFutstat?: string | null;
  indicationCode?: string | null;
  acceptTerms?: boolean;
}

export interface PlayerViewModelICollectionIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: PlayerViewModel[] | null;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface PlayerViewModelIQueryableIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: PlayerViewModel[] | null;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface PlayerViewModelIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: PlayerViewModel;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export enum PropertyAttributes {
  None = "None",
  SpecialName = "SpecialName",
  RTSpecialName = "RTSpecialName",
  HasDefault = "HasDefault",
  Reserved2 = "Reserved2",
  Reserved3 = "Reserved3",
  Reserved4 = "Reserved4",
  ReservedMask = "ReservedMask",
}

export interface PropertyInfo {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  /** @format int32 */
  metadataToken?: number;
  memberType?: MemberTypes;
  propertyType?: Type;
  attributes?: PropertyAttributes;
  isSpecialName?: boolean;
  canRead?: boolean;
  canWrite?: boolean;
  getMethod?: MethodInfo;
  setMethod?: MethodInfo;
}

export interface PublicKey {
  encodedKeyValue?: AsnEncodedData;
  encodedParameters?: AsnEncodedData;
  key?: AsymmetricAlgorithm;
  oid?: Oid;
}

export enum QueryNodeKind {
  None = "None",
  Constant = "Constant",
  Convert = "Convert",
  NonResourceRangeVariableReference = "NonResourceRangeVariableReference",
  BinaryOperator = "BinaryOperator",
  UnaryOperator = "UnaryOperator",
  SingleValuePropertyAccess = "SingleValuePropertyAccess",
  CollectionPropertyAccess = "CollectionPropertyAccess",
  SingleValueFunctionCall = "SingleValueFunctionCall",
  Any = "Any",
  CollectionNavigationNode = "CollectionNavigationNode",
  SingleNavigationNode = "SingleNavigationNode",
  SingleValueOpenPropertyAccess = "SingleValueOpenPropertyAccess",
  SingleResourceCast = "SingleResourceCast",
  All = "All",
  CollectionResourceCast = "CollectionResourceCast",
  ResourceRangeVariableReference = "ResourceRangeVariableReference",
  SingleResourceFunctionCall = "SingleResourceFunctionCall",
  CollectionFunctionCall = "CollectionFunctionCall",
  CollectionResourceFunctionCall = "CollectionResourceFunctionCall",
  NamedFunctionParameter = "NamedFunctionParameter",
  ParameterAlias = "ParameterAlias",
  EntitySet = "EntitySet",
  KeyLookup = "KeyLookup",
  SearchTerm = "SearchTerm",
  CollectionOpenPropertyAccess = "CollectionOpenPropertyAccess",
  CollectionComplexNode = "CollectionComplexNode",
  SingleComplexNode = "SingleComplexNode",
  Count = "Count",
  SingleValueCast = "SingleValueCast",
  CollectionPropertyNode = "CollectionPropertyNode",
  AggregatedCollectionPropertyNode = "AggregatedCollectionPropertyNode",
  In = "In",
  CollectionConstant = "CollectionConstant",
}

export interface QueryString {
  value?: string | null;
  hasValue?: boolean;
}

export interface RangeVariable {
  name?: string | null;
  typeReference?: IEdmTypeReference;
  /** @format int32 */
  kind?: number;
}

export interface RuntimeFieldHandle {
  value?: IntPtr;
}

export interface RuntimeMethodHandle {
  value?: IntPtr;
}

export interface RuntimeTypeHandle {
  value?: IntPtr;
}

export interface SafeWaitHandle {
  isClosed?: boolean;
  isInvalid?: boolean;
}

export interface SearchClause {
  expression?: SingleValueNode;
}

export interface SearchQueryOption {
  context?: ODataQueryContext;
  resultClrType?: Type;
  searchClause?: SearchClause;
  rawValue?: string | null;
}

export enum SecurityRuleSet {
  None = "None",
  Level1 = "Level1",
  Level2 = "Level2",
}

export interface SelectExpandClause {
  selectedItems?: SelectItem[] | null;
  allSelected?: boolean;
}

export interface SelectExpandQueryOption {
  context?: ODataQueryContext;
  rawSelect?: string | null;
  rawExpand?: string | null;
  compute?: ComputeQueryOption;
  validator?: ISelectExpandQueryValidator;
  selectExpandClause?: SelectExpandClause;
  /** @format int32 */
  levelsMaxLiteralExpansionDepth?: number;
}

export type SelectItem = object;

export interface SetRefereeMatchCriteria {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  refereeId?: number;
}

export interface SingleValueNode {
  typeReference?: IEdmTypeReference;
  kind?: QueryNodeKind;
}

export interface SkipQueryOption {
  context?: ODataQueryContext;
  rawValue?: string | null;
  /** @format int32 */
  value?: number;
  validator?: ISkipQueryValidator;
}

export type SkipTokenHandler = object;

export interface SkipTokenQueryOption {
  rawValue?: string | null;
  context?: ODataQueryContext;
  validator?: ISkipTokenQueryValidator;
  handler?: SkipTokenHandler;
}

export interface StateViewModel {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  continentId?: number;
  continentName?: string | null;
  /** @format int64 */
  countryId?: number;
  countryName?: string | null;
  code?: string | null;
  name?: string | null;
}

export interface Stream {
  canRead?: boolean;
  canWrite?: boolean;
  canSeek?: boolean;
  canTimeout?: boolean;
  /** @format int64 */
  length?: number;
  /** @format int64 */
  position?: number;
  /** @format int32 */
  readTimeout?: number;
  /** @format int32 */
  writeTimeout?: number;
}

export interface StringListResultBase {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: string[] | null;
  isSuccessStatusCode?: boolean;
  /** @format int32 */
  totalCount?: number | null;
  validationErros?: string[] | null;
}

export interface StringStringKeyValuePair {
  key?: string | null;
  value?: string | null;
}

export interface StringStringValuesKeyValuePair {
  key?: string | null;
  value?: string[];
}

export interface StructLayoutAttribute {
  typeId?: any;
  value?: LayoutKind;
}

export interface TeamViewModel {
  /** @format int64 */
  id?: number;
  name?: string | null;
  nickname?: string | null;
  modality?: string | null;
  colors?: string | null;
  uf?: string | null;
  cityName?: string | null;
  nameResp?: string | null;
  emailResp?: string | null;
  /** @format int32 */
  count?: number;
  /** @format int64 */
  createdByLogin?: number;
  /** @format date-time */
  dtCreated?: string;
  dtUpdated?: string | null;
  source?: string | null;
  logo?: string | null;
  teamCode?: string | null;
  /** @format int32 */
  countPlayers?: number | null;
}

export interface TeamViewModelETag {
  isWellFormed?: boolean;
  entityType?: Type;
  isAny?: boolean;
  isIfNoneMatch?: boolean;
}

export interface TeamViewModelIEnumerableIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: TeamViewModel[] | null;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface TeamViewModelIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: TeamViewModel;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface TeamViewModelODataQueryOptions {
  request?: HttpRequest;
  context?: ODataQueryContext;
  rawValues?: ODataRawQueryOptions;
  selectExpand?: SelectExpandQueryOption;
  apply?: ApplyQueryOption;
  compute?: ComputeQueryOption;
  filter?: FilterQueryOption;
  search?: SearchQueryOption;
  orderBy?: OrderByQueryOption;
  skip?: SkipQueryOption;
  skipToken?: SkipTokenQueryOption;
  top?: TopQueryOption;
  count?: CountQueryOption;
  validator?: IODataQueryValidator;
  ifMatch?: TeamViewModelETag;
  ifNoneMatch?: TeamViewModelETag;
}

export interface TimeSpan {
  /** @format int64 */
  ticks?: number;
  /** @format int32 */
  days?: number;
  /** @format int32 */
  hours?: number;
  /** @format int32 */
  milliseconds?: number;
  /** @format int32 */
  microseconds?: number;
  /** @format int32 */
  nanoseconds?: number;
  /** @format int32 */
  minutes?: number;
  /** @format int32 */
  seconds?: number;
  /** @format double */
  totalDays?: number;
  /** @format double */
  totalHours?: number;
  /** @format double */
  totalMilliseconds?: number;
  /** @format double */
  totalMicroseconds?: number;
  /** @format double */
  totalNanoseconds?: number;
  /** @format double */
  totalMinutes?: number;
  /** @format double */
  totalSeconds?: number;
}

export interface TopQueryOption {
  context?: ODataQueryContext;
  rawValue?: string | null;
  /** @format int32 */
  value?: number;
  validator?: ITopQueryValidator;
}

export interface TransformationNode {
  kind?: TransformationNodeKind;
}

export enum TransformationNodeKind {
  Aggregate = "Aggregate",
  GroupBy = "GroupBy",
  Filter = "Filter",
  Compute = "Compute",
  Expand = "Expand",
}

export interface Type {
  name?: string | null;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  /** @format int32 */
  metadataToken?: number;
  isInterface?: boolean;
  memberType?: MemberTypes;
  namespace?: string | null;
  assemblyQualifiedName?: string | null;
  fullName?: string | null;
  assembly?: Assembly;
  module?: Module;
  isNested?: boolean;
  declaringType?: Type;
  declaringMethod?: MethodBase;
  reflectedType?: Type;
  underlyingSystemType?: Type;
  isTypeDefinition?: boolean;
  isArray?: boolean;
  isByRef?: boolean;
  isPointer?: boolean;
  isConstructedGenericType?: boolean;
  isGenericParameter?: boolean;
  isGenericTypeParameter?: boolean;
  isGenericMethodParameter?: boolean;
  isGenericType?: boolean;
  isGenericTypeDefinition?: boolean;
  isSZArray?: boolean;
  isVariableBoundArray?: boolean;
  isByRefLike?: boolean;
  hasElementType?: boolean;
  genericTypeArguments?: Type[] | null;
  /** @format int32 */
  genericParameterPosition?: number;
  genericParameterAttributes?: GenericParameterAttributes;
  attributes?: TypeAttributes;
  isAbstract?: boolean;
  isImport?: boolean;
  isSealed?: boolean;
  isSpecialName?: boolean;
  isClass?: boolean;
  isNestedAssembly?: boolean;
  isNestedFamANDAssem?: boolean;
  isNestedFamily?: boolean;
  isNestedFamORAssem?: boolean;
  isNestedPrivate?: boolean;
  isNestedPublic?: boolean;
  isNotPublic?: boolean;
  isPublic?: boolean;
  isAutoLayout?: boolean;
  isExplicitLayout?: boolean;
  isLayoutSequential?: boolean;
  isAnsiClass?: boolean;
  isAutoClass?: boolean;
  isUnicodeClass?: boolean;
  isCOMObject?: boolean;
  isContextful?: boolean;
  isEnum?: boolean;
  isMarshalByRef?: boolean;
  isPrimitive?: boolean;
  isValueType?: boolean;
  isSignatureType?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  structLayoutAttribute?: StructLayoutAttribute;
  typeInitializer?: ConstructorInfo;
  typeHandle?: RuntimeTypeHandle;
  /** @format uuid */
  guid?: string;
  baseType?: Type;
  isSerializable?: boolean;
  containsGenericParameters?: boolean;
  isVisible?: boolean;
}

export enum TypeAttributes {
  NotPublic = "NotPublic",
  Public = "Public",
  NestedPublic = "NestedPublic",
  NestedPrivate = "NestedPrivate",
  NestedFamily = "NestedFamily",
  NestedAssembly = "NestedAssembly",
  NestedFamANDAssem = "NestedFamANDAssem",
  NestedFamORAssem = "NestedFamORAssem",
  SequentialLayout = "SequentialLayout",
  ExplicitLayout = "ExplicitLayout",
  LayoutMask = "LayoutMask",
  Interface = "Interface",
  Abstract = "Abstract",
  Sealed = "Sealed",
  SpecialName = "SpecialName",
  RTSpecialName = "RTSpecialName",
  Import = "Import",
  Serializable = "Serializable",
  WindowsRuntime = "WindowsRuntime",
  UnicodeClass = "UnicodeClass",
  AutoClass = "AutoClass",
  CustomFormatClass = "CustomFormatClass",
  HasSecurity = "HasSecurity",
  ReservedMask = "ReservedMask",
  BeforeFieldInit = "BeforeFieldInit",
  CustomFormatMask = "CustomFormatMask",
}

export interface TypeInfo {
  name?: string | null;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  /** @format int32 */
  metadataToken?: number;
  isInterface?: boolean;
  memberType?: MemberTypes;
  namespace?: string | null;
  assemblyQualifiedName?: string | null;
  fullName?: string | null;
  assembly?: Assembly;
  module?: Module;
  isNested?: boolean;
  declaringType?: Type;
  declaringMethod?: MethodBase;
  reflectedType?: Type;
  underlyingSystemType?: Type;
  isTypeDefinition?: boolean;
  isArray?: boolean;
  isByRef?: boolean;
  isPointer?: boolean;
  isConstructedGenericType?: boolean;
  isGenericParameter?: boolean;
  isGenericTypeParameter?: boolean;
  isGenericMethodParameter?: boolean;
  isGenericType?: boolean;
  isGenericTypeDefinition?: boolean;
  isSZArray?: boolean;
  isVariableBoundArray?: boolean;
  isByRefLike?: boolean;
  hasElementType?: boolean;
  genericTypeArguments?: Type[] | null;
  /** @format int32 */
  genericParameterPosition?: number;
  genericParameterAttributes?: GenericParameterAttributes;
  attributes?: TypeAttributes;
  isAbstract?: boolean;
  isImport?: boolean;
  isSealed?: boolean;
  isSpecialName?: boolean;
  isClass?: boolean;
  isNestedAssembly?: boolean;
  isNestedFamANDAssem?: boolean;
  isNestedFamily?: boolean;
  isNestedFamORAssem?: boolean;
  isNestedPrivate?: boolean;
  isNestedPublic?: boolean;
  isNotPublic?: boolean;
  isPublic?: boolean;
  isAutoLayout?: boolean;
  isExplicitLayout?: boolean;
  isLayoutSequential?: boolean;
  isAnsiClass?: boolean;
  isAutoClass?: boolean;
  isUnicodeClass?: boolean;
  isCOMObject?: boolean;
  isContextful?: boolean;
  isEnum?: boolean;
  isMarshalByRef?: boolean;
  isPrimitive?: boolean;
  isValueType?: boolean;
  isSignatureType?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  structLayoutAttribute?: StructLayoutAttribute;
  typeInitializer?: ConstructorInfo;
  typeHandle?: RuntimeTypeHandle;
  /** @format uuid */
  guid?: string;
  baseType?: Type;
  isSerializable?: boolean;
  containsGenericParameters?: boolean;
  isVisible?: boolean;
  genericTypeParameters?: Type[] | null;
  declaredConstructors?: ConstructorInfo[] | null;
  declaredEvents?: EventInfo[] | null;
  declaredFields?: FieldInfo[] | null;
  declaredMembers?: MemberInfo[] | null;
  declaredMethods?: MethodInfo[] | null;
  declaredNestedTypes?: TypeInfo[] | null;
  declaredProperties?: PropertyInfo[] | null;
  implementedInterfaces?: Type[] | null;
}

export interface TypeObjectKeyValuePair {
  key?: Type;
  value?: any;
}

export interface UpdateCompetitionCriteria {
  /** @format int64 */
  id?: number;
  name?: string | null;
  nameResp?: string | null;
  emailResp?: string | null;
  category?: string | null;
  division?: string | null;
  modality?: string | null;
  season?: string | null;
  uf?: string | null;
  cityName?: string | null;
  /** @format int64 */
  cityId?: number | null;
  type1?: string | null;
  type2?: string | null;
  type3?: string | null;
  type4?: string | null;
  oficial?: string | null;
  /** @format int32 */
  nTeams?: number;
  /** @format int32 */
  nGroups?: number;
  orgSource?: string | null;
  showInApp?: string | null;
  enrolPeriod?: string | null;
  /** @format int32 */
  nTeamsNext?: number;
  fixedPlace?: string | null;
  /** @format int64 */
  competitionGroupId?: number;
  image?: FileCriteria;
}

export interface UpdateMatchCriteria {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  game?: number;
  phase?: string | null;
  cGroup?: string | null;
  round?: string | null;
  /** @format int64 */
  teamHomeId?: number | null;
  /** @format int64 */
  teamAwayId?: number | null;
  iniLabelTeamHome?: string | null;
  iniLabelTeamAway?: string | null;
  /** @format int64 */
  competitionId?: number;
  place?: string | null;
  /** @format date-time */
  dtGame?: string | null;
  hrGame?: TimeSpan;
  /** @format int32 */
  count?: number;
  gameOrder?: string | null;
  /** @format int32 */
  daysMatchRestriction?: number;
}

export interface UpdatePasswordCriteria {
  /** @format int64 */
  id?: number;
  password?: string | null;
  confirmPassword?: string | null;
  token?: string | null;
}

export interface UpdatePlayerCompetitionRegisterCriteria {
  /** @format int64 */
  competitionId?: number;
  /** @format int64 */
  teamId?: number;
  /** @format int64 */
  playerId?: number;
  position?: string | null;
  coach?: string | null;
  playerNumberDefault?: string | null;
  doc?: string | null;
  birthday?: string | null;
  docFederacao?: string | null;
  docCbf?: string | null;
  docCbfs?: string | null;
  docOther?: string | null;
  phone?: string | null;
  docCpf?: string | null;
  nickName?: string | null;
}

export interface UpdateTeamCriteria {
  /** @format int64 */
  id?: number | null;
  name?: string | null;
  nameResp?: string | null;
  modality?: CompetitionModalitiesEnum;
  uf?: string | null;
  nickname?: string | null;
  emailResp?: string | null;
  colors?: string | null;
  cityName?: string | null;
  /** @format int64 */
  createdByLogin?: number | null;
  image?: FileCriteria;
}

export interface UpdateUserCriteria {
  /** @format int64 */
  id?: number;
  name?: string | null;
  phoneNumber?: string | null;
  /** @format date-time */
  birthday?: string;
  doc?: string | null;
  cpf?: string | null;
  acceptTerms?: boolean;
}

export interface UserViewModel {
  /** @format int64 */
  id_Login?: number;
  name?: string | null;
  login?: string | null;
  roles?: AccessRoleType[] | null;
  token?: string | null;
  player?: PlayerViewModel;
}

export interface UserViewModelIResult {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: UserViewModel;
  /** @format int32 */
  totalCount?: number | null;
  isSuccessStatusCode?: boolean;
  validationErros?: string[] | null;
}

export interface UserViewModelResultBase {
  message?: string | null;
  /** @format int32 */
  status?: number;
  result?: UserViewModel;
  isSuccessStatusCode?: boolean;
  /** @format int32 */
  totalCount?: number | null;
  validationErros?: string[] | null;
}

export interface VerifyCodeCriteria {
  /** @format int64 */
  loginId?: number;
  verifyCode?: string | null;
}

export interface WaitHandle {
  handle?: IntPtr;
  safeWaitHandle?: SafeWaitHandle;
}

export interface WebSocketManager {
  isWebSocketRequest?: boolean;
  webSocketRequestedProtocols?: string[] | null;
}

export interface X500DistinguishedName {
  oid?: Oid;
  /** @format byte */
  rawData?: string | null;
  name?: string | null;
}

export interface X509Certificate2 {
  handle?: IntPtr;
  issuer?: string | null;
  subject?: string | null;
  serialNumberBytes?: ByteReadOnlyMemory;
  archived?: boolean;
  extensions?: X509Extension[] | null;
  friendlyName?: string | null;
  hasPrivateKey?: boolean;
  privateKey?: AsymmetricAlgorithm;
  issuerName?: X500DistinguishedName;
  /** @format date-time */
  notAfter?: string;
  /** @format date-time */
  notBefore?: string;
  publicKey?: PublicKey;
  /** @format byte */
  rawData?: string | null;
  rawDataMemory?: ByteReadOnlyMemory;
  serialNumber?: string | null;
  signatureAlgorithm?: Oid;
  subjectName?: X500DistinguishedName;
  thumbprint?: string | null;
  /** @format int32 */
  version?: number;
}

export interface X509Extension {
  oid?: Oid;
  /** @format byte */
  rawData?: string | null;
  critical?: boolean;
}

export interface V1CompetitiongroupsListParams {
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
}

export interface V1CompetitionsListParams {
  /** @format int64 */
  CompetitionGroupId?: number;
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
}

export interface V1CompetitionsTeamsDetailParams {
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
  /** @format int64 */
  id: number;
}

export interface V1CompetitionsTeamsPlayersDetailParams {
  /** @format int32 */
  type?: number;
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
  /** @format int64 */
  id: number;
  /** @format int64 */
  teamId: number;
}

export interface V1CompetitionsTeamsPlayerDetailParams {
  /** @format int64 */
  playerId?: number;
  /** @format int64 */
  id: number;
  /** @format int64 */
  teamId: number;
}

export interface V1CompetitionsTeamsPlayersExportDetailParams {
  query?: CompetitionTeamPlayerSubscribeViewModelODataQueryOptions;
  language?: DefaultLanguageEnum;
  /** @format int32 */
  type?: number;
  /** @format int64 */
  id: number;
  /** @format int64 */
  teamId: number;
}

export interface V1CompetitionsTeamsPlayersExportDetail2Params {
  language?: DefaultLanguageEnum;
  /** @format int64 */
  id: number;
}

export interface V1CompetitionsMatchsDetailParams {
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
  /** @format int64 */
  id: number;
}

export interface V1GeolocationCountriesListParams {
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
}

export interface V1GeolocationStatesListParams {
  /** @format int64 */
  countryId?: number;
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
}

export interface V1GeolocationCitiesListParams {
  /** @format int64 */
  stateId?: number;
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
}

export interface V1MatchsGenerateDraftGroupDetailParams {
  /** @format int32 */
  groupNumber?: number;
  teams?: number[];
  random?: boolean;
  /** @format int64 */
  competitionId: number;
}

export interface V1OrganizationsListParams {
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
}

export interface V1PlayersListToInviteForCompetitionDetailParams {
  searchTerm?: string;
  /** @format int64 */
  competitionId: number;
}

export interface V1TeamsListParams {
  modality?: string;
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
}

export interface V1UserListParams {
  searchTerm?: string;
  /** The max number of records. */
  $top?: string;
  /** The number of records to skip. */
  $skip?: string;
  /** A function that must evaluate to true for a record to be returned. */
  $filter?: string;
  /** Specifies a subset of properties to return. Use a comma separated list. */
  $select?: string;
  /** Determines what values are used to order a collection of records. */
  $orderby?: string;
  /** Use to add related query data. */
  $expand?: string;
}
