import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { Button, ButtonProps, Tooltip} from "@mui/material";
import { SxProps } from "@mui/system";
import { Link as RouteLink } from "react-router-dom";

interface ButtonWithTextProps extends ButtonProps {
  component?: typeof RouteLink;
  to?: string;
  sx?: SxProps;
  onClick?: () => void;
  tooltipText?: ''
  isSubmiting?: boolean;
}

export default function ButtonWithText({ sx, onClick, children, tooltipText, isSubmiting, ...other }: ButtonWithTextProps) {

  let errorButtonStyle = other.color === 'error' ? {backgroundColor:'#fcc5c5', color: '#991B1B', '&.iconify': {color: '#991B1B'}, '&:hover': {backgroundColor: '#FEF2F2'}} : {}

  return(
    <Tooltip title={tooltipText ?? ''}>
      <LoadingButton
        loading={isSubmiting}
        sx={{...sx, ...{backgroundColor:'#562B7F', color: 'white','&.iconify': {color: 'white'}, '&:hover': {backgroundColor: '#3d1d5c'}}, ...errorButtonStyle }}
        {...other}      
        variant={other.variant ?? 'contained'}
        onClick={onClick}>{children}            
      </LoadingButton >
    </Tooltip>
  );
}